import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './ContactFormSection.scss'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import axios from 'axios'

const STATUSES = {
  SUCCESS: 'success',
  FAIL: 'fail',
  IDLE: 'idle',
}

const ContactFormSection = ({ transparent }) => {
  const [status, setStatus] = useState(STATUSES.IDLE)
  const [values, setValues] = useState({
    name: '',
    email: '',
    message: '',
  })

  const sendMail = (values) => {
    axios({
      method: 'POST',
      url: 'https://www.lipus-safety.pl/api/',
      data: JSON.stringify({
        name: values.name,
        email: values.email,
        message: values.message,
      }),
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then(() => {
        setValues({ name: values.name, email: values.email, message: values.message })
        setStatus(STATUSES.SUCCESS)
      })
      .catch(() => {
        setStatus(STATUSES.FAIL)
      })
  }

  const resetForm = () => {
    setValues({ name: '', email: '', message: '' })
    setStatus(STATUSES.IDLE)
  }

  return (
    <div
      data-id="contactForm"
      className={`contactformSection ${transparent ? 'contactformSection--transparent' : ''}`}
    >
      <div className="container container--page">
        <h4 className="contactformSection__pretitle">MASZ PYTANIE?</h4>
        <h3 className="contactformSection__title">NAPISZ DO NAS</h3>
        {status === STATUSES.IDLE && (
          <Formik
            initialValues={values}
            validate={(values) => {
              const errors = {}
              if (!values.name) {
                errors.name = 'Pole nie może być puste'
              }
              if (!values.email) {
                errors.email = 'Pole nie może być puste'
              } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = 'Niepoprawny adres email'
              }
              if (!values.message) {
                errors.message = 'Pole nie może być puste'
              }
              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                sendMail(values)
                setSubmitting(false)
              }, 400)
            }}
          >
            {({ isSubmitting }) => (
              <Form className="contactformSection__form">
                <label htmlFor="name">Imię</label>
                <Field
                  id="name"
                  className="contactformSection__field contactformSection__field--small"
                  type="text"
                  name="name"
                />
                <ErrorMessage name="name" component="div" className="contactformSection__error" />
                <label htmlFor="email">Mail</label>
                <Field
                  id="email"
                  className="contactformSection__field contactformSection__field--small"
                  type="email"
                  name="email"
                />
                <ErrorMessage name="email" component="div" className="contactformSection__error" />
                <label htmlFor="message">Wiadomość</label>
                <Field
                  id="message"
                  component="textarea"
                  className="contactformSection__field contactformSection__field--big"
                  name="message"
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  className="contactformSection__error"
                />
                <button
                  className="contactformSection__button"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Wyślij
                </button>
              </Form>
            )}
          </Formik>
        )}
        {status === STATUSES.SUCCESS && (
          <div className={`contactformSection__msg contactformSection__msg--success`}>
            <p>Wiadomość została wysłana.</p>
            <button className="contactformSection__button" type="button" onClick={resetForm}>
              Wyślij kolejną wiadomość
            </button>
          </div>
        )}
        {status === STATUSES.FAIL && (
          <div className={`contactformSection__msg contactformSection__msg--fail`}>
            <p>Coś poszło nie tak.</p>
            <button className="contactformSection__button" type="button" onClick={resetForm}>Spróbuj ponownie</button>
          </div>
        )}
      </div>
    </div>
  )
}

ContactFormSection.propTypes = {
  transparent: PropTypes.bool,
}

ContactFormSection.defaultProps = {
  transparent: false,
}

export default ContactFormSection
